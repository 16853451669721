import React, { useState } from "react";
import { Link } from "react-router-dom";
import { userService } from "../../services/user.service";
import swal from "sweetalert";
import { RecoverPassword } from "./recoverPassword";
import "./login.scss";

import LogoCompany from "../../assets/imgs/R_C.png";
import IcoUser from "../../assets/imgs/iconos/ico_user.png";
import IcoPass from "../../assets/imgs/iconos/ico_pass.png";
import { useSelector } from "react-redux";
import { ImgCache } from "../../components/CustonHooks/imgCache";
import { Legals } from "../../components/legals/legals";

export function Login(props) {
  const companyLogo = useSelector((state) => state.userSettings.companyLogo);
  const accountSettings = useSelector((state) => state.accountSettings);

  const handleSubmit = (event) => {
    event.preventDefault();

    let params = {
      username: event.target.user.value,
      password: event.target.password.value,
      productSubscription: accountSettings.productSubscription,
    };

    userService.login(params).then((result) => {
      if (result.responseCode == "00") {
        let roleName = localStorage.getItem("roleName");
        if (roleName === "Operaciones") props.history.push("/homeOps/1");
        else props.history.push("/home");
      } else if (result.responseCode == "11") {
        props.history.push({
          pathname: "/membership",
          state: result.data,
        });
      } else {
        swal({ text: "Datos incorrectos.", icon: "error" });
      }
    });
  };

  const [showLogeo, setShowLogeo] = useState(false);
  const toggleLogeo = () => setShowLogeo(!showLogeo);

  const CargarLogeo = () => {
    return showLogeo === false ? (
      <div>
        <span
          to="#"
          className="btn_links trans text-muted"
          onClick={toggleLogeo}
        >
          Olvidaste tu contraseña
        </span>
      </div>
    ) : (
      <div>
        <span
          to="#"
          className="btn_links trans text-muted"
          onClick={toggleLogeo}
        >
          Iniciar sesión
        </span>
      </div>
    );
  };

  const [showPass, setShowPass] = useState("password");
  const [showIco, setShowIco] = useState("fa-eye-slash");

  const setInputPass = () => {
    setShowPass(showPass === "password" ? "text" : "password");
    setShowIco(showIco === "fa-eye-slash" ? "fa-eye" : "fa-eye-slash");
  };

  return (
    <div className="wrapper_login">
      <div className="content_login">
        <div className="box_msj">
          <div>
            <h1 className="text fnt_black ttls_black">Bienvenidos</h1>
            <p className="paragraph parag_big mb-0">
              Únete a este gran equipo e interactúa con nosotros.
            </p>
          </div>
        </div>
        <div className="box_form">
          <div className="box_inputs">
            <div className="box_logo_company_login">
              <ImgCache
                src={companyLogo ? companyLogo : LogoCompany}
                alt="Name Company"
              />
            </div>
            <div className="box_content_form">
              {showLogeo === false ? (
                <form onSubmit={handleSubmit}>
                  <h2 className="ttl_form text fnt_black ttls_black">
                    Ingresa a tu cuenta
                  </h2>
                  <div className="form-group">
                    <span className="box_input_icon">
                      <input
                        type="email"
                        className="form-control"
                        id="user"
                        aria-describedby="emailHelp"
                        placeholder="Correo electrónico"
                      />
                      <span className="ico_input">
                        <img src={IcoUser} alt="User" />
                      </span>
                    </span>
                  </div>
                  <div className="form-group">
                    <span className="box_input_icon">
                      <input
                        type={showPass}
                        className="form-control"
                        id="password"
                        placeholder="Contraseña"
                      />
                      <span className="ico_show_pass" onClick={setInputPass}>
                        <i className={`fas ${showIco}`}></i>
                      </span>
                      <span className="ico_input">
                        <img src={IcoPass} alt="Password" />
                      </span>
                    </span>
                  </div>
                  <div className="box_btn_login">
                    <Link to="/register" className="btn secundary">
                      Regístrate
                    </Link>
                    <button type="submit" className="btn primary">
                      Iniciar sesión
                    </button>
                  </div>
                </form>
              ) : (
                <RecoverPassword />
              )}
              <CargarLogeo />
            </div>
            <Legals />
          </div>
        </div>
      </div>
    </div>
  );
}
